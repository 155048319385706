
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

//import '@govflanders/vl-widget-polyfill';
import * as widgets from '@govflanders/vl-widget-client';

@Component
export default class WidgetEmbed extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  public embedUrl!: string;

  @Prop({
    type: String,
    required: true,
  })
  public type!: string;

  mounted() {
    this.mountWidgets();
  }

  @Watch('embedUrl')
  private async mountWidgets(): Promise<any> {
    this.clearParent();

    return Promise.all([
      widgets
        .bootstrap(this.embedUrl)
        .then(widget => {
          // Get the Citizen Profile Session extension from the global header widget.
          widget.getExtension('citizen_profile.session').then(function(session: any) {
            // This variable is purely to indicate which values are allowed (true / false).
            const websiteHasAuthenticatedSession = false;
            // Inform the session extension about the current session state of the website.
            session.configure({
              active: websiteHasAuthenticatedSession,
              endpoints: {
                loginUrl: '/login',
                loginRedirectUrl: '/profile',
                logoutUrl: '/logout',
              },
            });
          });
          if (!document.getElementById('headerWrapperElement')) {
            const headerWrapperElement = document.createElement('div');
            headerWrapperElement.id = 'headerWrapperElement';
            const parentContainer = document.querySelector(
              '.widget-embed-placeholder-' + this.type,
            );
            widget.setMountElement(headerWrapperElement);
            widget.mount();
            parentContainer?.insertBefore(
              headerWrapperElement,
              parentContainer.childNodes[0] || null,
            );
          }
        })
        .catch(err => {
          this.$emit('error', err);
          this.clearParent();
        }),
    ]);
  }

  private clearParent() {
    const parentContainer = document.querySelector('.widget-embed-placeholder-' + this.type);
    if (parentContainer) {
      parentContainer.innerHTML = '';
    }
  }
}
